import React from "react"
import Navbar from "../components/navbar"
import AuthOverlay from './authOverlay'

const LayoutSearch = ({ children }) => {
  return (
    <>
    <AuthOverlay />
    <div>
        <Navbar></Navbar>
        <div className={' text-gray-800'}>
          {children}
        </div>
      </div>
    </>
  )
}

export default LayoutSearch